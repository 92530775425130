import React from 'react';
import { Row, Col, Button } from 'antd';
import SolutionOfBanner from './solution-of-banner.jpg';
import SolutionImg1 from './solution-img1.png';
import SolutionImg2 from './solution-img2.png';
import SolutionImg3 from './solution-img3.png';
import SolutionImg4 from './solution-img4.png';
import SolutionImg5 from './solution-img5.png';
import SolutionImg6 from './solution-img6.png';
import { Link, withRouter } from 'react-router-dom';
import './index.scss';

class Index extends React.Component {

  componentWillMount() {
    document.getElementById('root').scrollIntoView(true);//为ture返回顶部，false为底部
  }

  render() {
    return (
      <div className="solution">
        <div className="solution-of-banner">
          <img className="solution-of-banner-img" src={SolutionOfBanner} alt="" />
        </div>
        <div className="solution-title-box">
          <div className="solution-title" data-aos="fade-up">
            我们的产品
					</div>
          <div className="solution-sub-title" data-aos="fade-up">
            我们竭力打造更好的产品为您服务
					</div>
        </div>
        <div className="solution-container">
          <Row gutter={16} className="solution-row" style={{ marginTop: '4%' }}>
            <Col span={12} data-aos="zoom-in">
              <img className="solution-img" src={SolutionImg1} alt="" />
            </Col>
            <Col span={12} data-aos="fade-up">
              <div className="solution-box">
                <div className="solution-item-title">智慧生产</div>
                <div className="solution-info">
                  恒沁科技打造智慧生产，包含生产调度系统 、水质监测系统、二次加压集成系统、管网巡检系统。系统将生产监控SCADA、GIS、调度和业务管理统一到一个综合性的系统平台，集数据采集、监控、分析、决策于一体，为用户保障供水安全，提高供水企业的经济效益和社会效益。
								</div>
                <div className="solution-btn">
                  <Button shape="round"><Link to={{ pathname: '/solutionDetail', state: { defaultOpenKeys: 'sub1', defaultSelectedKeys: '1-1' } }}>了解详情</Link></Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={16} className="solution-row">
            <Col span={12} data-aos="fade-up">
              <div className="solution-box">
                <div className="solution-item-title">智慧输水</div>
                <div className="solution-info">
                  业务包含管网地理信息系统、DMA漏损管理、 管网水力模型。通过系统的建设和应用，提高管网管理和养护能力、现场事故处理速度，使供水企业获得运营经济效益，实现管网管理的科学化、规范化和现代化。
								</div>
                <div className="solution-btn">
                  <Button shape="round"><Link to={{ pathname: '/solutionDetail', state: { defaultOpenKeys: 'sub2', defaultSelectedKeys: '2-1' } }}>了解详情</Link></Button>
                </div>
              </div>
            </Col>
            <Col span={12} data-aos="zoom-in">
              <img className="solution-img" src={SolutionImg2} alt="" />
            </Col>
          </Row>
          <Row gutter={16} className="solution-row" >
            <Col span={12} data-aos="fade-right">
              <img className="solution-img" src={SolutionImg3} alt="" />
            </Col>
            <Col span={12} data-aos="fade-up">
              <div className="solution-box">
                <div className="solution-item-title">智慧售水</div>
                <div className="solution-info">
                  业务包含统一营收系统、 表务管理系统。营业管理系统应完整地覆盖了水司营业管理的全部过程，术语定义应科学规范，结构设计严谨精细，功能设计全面完整，可最大限度满足客户的自定义需求，具有很强的通用性。通过表务系统，供水企业可以做到对每块水表情况了解，规避了人为因素造成的企业损失，在企业资源控制上收益明显。
								</div>
                <div className="solution-btn">
                  <Button shape="round"><Link to={{ pathname: '/solutionDetail', state: { defaultOpenKeys: 'sub3', defaultSelectedKeys: '3-1' } }}>了解详情</Link></Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={16} className="solution-row" >
            <Col span={12} data-aos="fade-up">
              <div className="solution-box">
                <div className="solution-item-title">智慧服务</div>
                <div className="solution-info">
                  业务包含客服热线系统、水务电子营业厅。企业与客户沟通的有效渠道，通过统一的对外服务电话为客户提供咨询、报修、投诉、查询等多方面的服务，实现“一站式服务”管理。网上营业厅为您提供水费查询、水费在线缴费、业务办理、费用查询、帐单查询、在线客服、故障申告、投诉咨询及业务介绍等全方位的电子自助服务平台
								</div>
                <div className="solution-btn">
                  <Button shape="round"><Link to={{ pathname: '/solutionDetail', state: { defaultOpenKeys: 'sub4', defaultSelectedKeys: '4-1' } }}>了解详情</Link></Button>
                </div>
              </div>
            </Col>
            <Col span={12} data-aos="zoom-in">
              <img className="solution-img" src={SolutionImg4} alt="" />
            </Col>
          </Row>
          <Row gutter={16} className="solution-row">
            <Col span={12} data-aos="zoom-in">
              <img className="solution-img" src={SolutionImg5} alt="" />
            </Col>
            <Col span={12} data-aos="fade-up">
              <div className="solution-box">
                <div className="solution-item-title">智慧决策</div>
                <div className="solution-info">
                  以“数据+地图+业务”为中心，整合SCADA系统、供水管网、营收系统、客服系统的运行数据，通过供水数据建模分析、管网空间分析、水力学模型，以更加“智慧”的方式辅助水务企业进行管理和运维，保证可靠供水，降低产销差，提升运营管控能力，实现经济效益和社会效益的不断增长。
								</div>
                <div className="solution-btn">
                  <Button shape="round"><Link to={{ pathname: '/solutionDetail', state: { defaultOpenKeys: 'sub5', defaultSelectedKeys: '5-1' } }}>了解详情</Link></Button>
                </div>
              </div>
            </Col>
          </Row>
          <Row gutter={16} style={{ margin: '20% 0 0 0', padding: '0 0 20% 0' }}>
            <Col span={12} data-aos="fade-up">
              <div className="solution-box">
                <div className="solution-item-title">智慧管理</div>
                <div className="solution-info">
                  智慧OA旨在打造一站式全业务体验。全领域协同办公，以支撑企业战略规划，全方位提高企业执行力、运营效率和管控能力。借助OA协同办公系统，水司能够高效地对日常办公进行自动化流转和管理。
								</div>
                <div className="solution-btn">
                  <Button shape="round"><Link to={{ pathname: '/solutionDetail', state: { defaultOpenKeys: 'sub6', defaultSelectedKeys: '6-1' } }}>了解详情</Link></Button>
                </div>
              </div>
            </Col>
            <Col span={12} data-aos="zoom-in">
              <img className="solution-img" src={SolutionImg6} alt="" />
            </Col>
          </Row>
        </div>
      </div>
    );
  }
}

export default withRouter(Index);
