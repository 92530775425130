import React from 'react';
import { Row, Col } from 'antd';
import Zmage from 'react-zmage';
import AboutOfBanner from './about-of-banner.jpg';
import CompanyProfile from './company-profile.png';
import AboutOfImg from './about-of-img.png'; // 大楼图片
import DevelopmentHistory from './development-history.png';
import AboutDivider from './about-divider.png';
// 发展历程图标（按时间命名） 开始
import About201705 from './about-2017-05.png';
import About201708 from './about-2017-08.png';
import About201805 from './about-2018-05.png';
import About201809 from './about-2018-09.png';
import About201906 from './about-2019-06.png';
import About201911 from './about-2019-11.png';
import About202005 from './about-2020-05.png';
// 发展历程图标（按时间命名） 结束
import AboutTimeLine from './about-time-line.png';
import CompanyHonor from './company-honor.png';
// 公司荣誉图片 开始
import AboutCertificateImg1 from './about-certificate-img1.jpg';
import AboutCertificateImg2 from './about-certificate-img2.jpg';
import AboutCertificateImg3 from './about-certificate-img3.jpg';
import AboutCertificateImg4 from './about-certificate-img4.jpg';
import AboutCertificateImg5 from './about-certificate-img5.jpg';
import AboutCertificateImg6 from './about-certificate-img6.jpg';
import AboutCertificateImg7 from './about-certificate-img7.jpg';
import AboutCertificateImg8 from './about-certificate-img8.jpg';
import AboutCertificateImg9 from './about-certificate-img9.jpg';
import AboutCertificateImg10 from './about-certificate-img10.jpg';
import AboutCertificateImg11 from './about-certificate-img11.jpg';
import AboutCertificateImg12 from './about-certificate-img12.jpg';
import AboutCertificateImg13 from './about-certificate-img13.jpg';
// 公司荣誉图片 结束
import 'antd/dist/antd.css';
import './index.scss';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: 'about'
    };
  }

  componentWillMount() {
    document.getElementById('root').scrollIntoView(true); //为ture返回顶部，false为底部
  }

  onChange = (current) => {
    console.log('onChange:', current);
    this.setState({ current });
  };

  render() {
    return (
      <div className="about">
        <div className="about-of-banner">
          <img src={AboutOfBanner} alt="" />
        </div>
        {/* 公司简介 start */}
        <div className="company-profile">
          <Row className="company-profile-row">
            <Col className="company-profile-col" span={12} data-aos="fade-up">
              <div className="company-profile-col-box">
                <div>
                  <img className="about-title-img" src={CompanyProfile} alt="" />
                </div>
                <div className="about-title">
                  公司简介
								</div>
                <br />
                <div className="about-info" style={{ textIndent: '2em' }}>
                  江苏恒沁科技有限公司是一家专业从事软硬件开发、定制和实施的高新技术企业，坐落于风景 如画的宜居名城江苏扬州。公司拥有一批长期专业从事规划咨询、产品设计、软件开发的专业人才，具有雄厚的技术开发实力，全方位满足政府与企业信息化需求，目前公司研发团队20余 人，其中硕士学历占比40%以上，本科占比90%以上。
								</div>
                <br />
                <div className="about-subtitle">服务领域</div>
                <div className="about-info">
                  <p>
                    <span className="about-info-title" >
                      软件研发：
                    </span>
                    行业管理软件/应用软件的设计与开发，涉足但不限于水务、电信、政府等相关行业， 包括PC、APP、H5、公众号、小程序、自助终端等。
									</p>
                  <p>
                    <span className="about-info-title" >咨询规划：</span> 针对企业要求，为客户提供整体解决方案的规划与设计
									</p>
                  <p>
                    <span className="about-info-title" >系统集成：</span>
										针对企业现状，提供整合，封装，扩展服务，将企业的IT资源进行有效整合和充分利用。
									</p>
                  <p>
                    <span className="about-info-title" >产品宣传：</span>
										为企业提供产品设计、包装，宣传制作等工作，并根据企业特点选择合适的宣传推广渠道与方式，提升企业的品牌形象。
									</p>
                </div>
              </div>
            </Col>
            <Col className="company-profile-col" span={10} offset={2} data-aos="fade-up">
              <div className="company-profile-col-box">
                <img className="company-profile-col-box-building" src={AboutOfImg} alt="" />
              </div>
            </Col>
          </Row>
        </div>
        {/* 公司简介 end */}
        {/* 发展历程 start */}
        <div className="about-course">
          <div data-aos="zoom-in">
            <img className="about-title-img" src={DevelopmentHistory} alt="" />
          </div>
          <div className="about-title" data-aos="zoom-in">
            发展历程
					</div>
          <div data-aos="zoom-in">
            <img className="about-divider" src={AboutDivider} alt="" />
          </div>
          <div className="sub-title" data-aos="fade-up">
            我们希望通过互联网的方式，用互联网最先进最安全最稳定的技术与架构， 设计最合理的方案，为各行业各企业提供优质完美的解决方案，致力于共成长！
					</div>
          <div>
            <div className="about-time-box">
              <div className="about-time-box-item" data-aos="fade-up">
                <img className="about-time-img" src={About201705} alt="" />
                <p className="about-time-txt">参与第一个微支付项目，并上线，解决自来水公司方便用户微信交费与刷卡的需求</p>
              </div>
              <div className="about-time-box-item" style={{ marginTop: '-4rem' }} data-aos="fade-up">
                <img className="about-time-img" src={About201708} alt="" />
                <p className="about-time-txt">规划公司公司发展战略与智慧水务产品</p>
              </div>
              <div className="about-time-box-item" style={{ marginTop: '2rem' }} data-aos="fade-up">
                <img className="about-time-img" src={About201805} alt="" />
                <p className="about-time-txt">5月7日公司注册成立，注册资本1000万</p>
              </div>
              <div className="about-time-box-item" style={{ marginTop: '-4rem' }} data-aos="fade-up">
                <img className="about-time-img" src={About201809} alt="" />
                <p className="about-time-txt">宝应纪委第一个项目上线；通辽智慧水务合同签订，智慧水务一期OA、GIS、DMA、营收等系统开始研发</p>
              </div>
              <div className="about-time-box-item" style={{ marginTop: '2rem' }} data-aos="fade-up">
                <img className="about-time-img" src={About201906} alt="" />
                <p className="about-time-txt">通辽智慧水务二期项目的研发上线，启动满洲里锦源水务公司的水务大数据平台开发,申请发明专利3-5项</p>
              </div>
              <div className="about-time-box-item" style={{ marginTop: '-4rem' }} data-aos="fade-up">
                <img className="about-time-img" src={About201911} alt="" />
                <p className="about-time-txt">总经理获得扬州市“绿扬金凤计划”、“领军人才奖”、“扬州经济技术开发区高层次创业创新领军人才”</p>
              </div>
              <div className="about-time-box-item" data-aos="fade-up" >
                <img className="about-time-img" src={About202005} alt="" />
                <p className="about-time-txt">公司获得软件企业评估认定 软件著作权一份，获得5项软件产品</p>
              </div>
            </div>
            <div data-aos="fade-up">
              <img className="about-time-line" src={AboutTimeLine} alt="" />
              <div style={{ display: 'flex' }}>
                <div style={{ flex: 1, marginLeft: '18px' }}>2017.05</div>
                <div style={{ flex: 1 }}>2017.08</div>
                <div style={{ flex: 1 }}>2018.05</div>
                <div style={{ flex: 1 }}>2018.09</div>
                <div style={{ flex: 1 }}>2019.06</div>
                <div style={{ flex: 1 }}>2019.11</div>
                <div style={{ flex: 1 }}>2020.05</div>
              </div>
            </div>
          </div>
        </div>
        {/* 发展历程 end */}
        {/* 公司荣誉 start */}
        <div style={{ margin: '3vh 15vw', minHeight: '100vh', textAlign: 'left' }}>
          <div data-aos="zoom-in">
            <img className="about-title-img" src={CompanyHonor} alt="" />
          </div>
          <div className="about-title" data-aos="zoom-in">
            公司荣誉
					</div>
          <div data-aos="zoom-in">
            <img className="about-divider" src={AboutDivider} alt="" />
          </div>
          <div>
            <Row className="about-carousel">
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img"
                  src={AboutCertificateImg8}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg8 })}
                  alt=""
                  data-aos="fade-up" />
              </Col>
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img"
                  src={AboutCertificateImg2}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg2 })}
                  alt=""
                  data-aos="fade-up" />
              </Col>
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img"
                  src={AboutCertificateImg5}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg5 })}
                  alt=""
                  data-aos="fade-up" />
              </Col>
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img"
                  src={AboutCertificateImg1}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg1 })}
                  alt=""
                  data-aos="fade-up" />
              </Col>
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img"
                  src={AboutCertificateImg4}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg4 })}
                  alt=""
                  data-aos="fade-up" />
              </Col>
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img"
                  src={AboutCertificateImg3}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg3 })}
                  alt=""
                  data-aos="fade-up" />
              </Col>
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img"
                  src={AboutCertificateImg6}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg6 })}
                  alt=""
                  data-aos="fade-up" />
              </Col>
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img"
                  src={AboutCertificateImg9}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg9 })}
                  alt=""
                  data-aos="fade-up" />
              </Col>
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img"
                  src={AboutCertificateImg7}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg7 })}
                  alt=""
                  data-aos="fade-up" />
              </Col>
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img-horizontal"
                  src={AboutCertificateImg11}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg11 })}
                  alt=""
                  data-aos="fade-up" />
              </Col>
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img-horizontal"
                  src={AboutCertificateImg12}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg12 })}
                  alt=""
                  data-aos="fade-up"
                />
              </Col>
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img-horizontal"
                  src={AboutCertificateImg13}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg13 })}
                  alt=""
                  data-aos="fade-up" />
              </Col>
              <Col span={8} className="about-carousel-item"></Col>
              <Col span={8} className="about-carousel-item">
                <img
                  className="about-carousel-img"
                  src={AboutCertificateImg10}
                  onClick={() => Zmage.browsing({ src: AboutCertificateImg10 })}
                  alt=""
                  data-aos="fade-up" />
              </Col>
              <Col span={8} className="about-carousel-item"></Col>
            </Row>
          </div>
        </div>
        {/* 公司荣誉 end */}
      </div>
    );
  }
}

export default Index;
