import React from 'react';
import './App.css';
import RouterConfig from './router/index';
import AOS from 'aos';
import 'aos/dist/aos.css';

AOS.init({
	delay: 100,
	duration: 800
});

function App() {
  try{if(window.console&&window.console.log){console.log("We may not be talented \n");console.log("We may not be perfect \n");console.log("But we will always be making a difference \n");console.log("If you are interested in what we do \n");console.log("Please mail to %c ZG9uZ2JpbmdxaUBldmVycWluLmNvbQ== ","color:red");}}catch(e){};
	return (
		<div className="App">
      <RouterConfig></RouterConfig>
		</div>
	);
}

export default App;
