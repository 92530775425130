import React from 'react';
import { Row, Col, Menu } from 'antd';
import { withRouter } from 'react-router-dom';
import SolutionOfBanner from './solution-of-banner.jpg';
// 智慧生产
import productionScheduling from './production-scheduling.jpg'; // 生产调度系统
import productionMonitor from './production-monitor.jpg'; // 水质监测系统
import productionPressurize01 from './production-pressurize-01.jpg'; // 二次加压集成系统1
import productionPressurize02 from './production-pressurize-02.jpg'; // 二次加压集成系统2
import productionNetwork01 from './production-network-01.jpg'; // 管网巡检系统1
import productionNetwork02 from './production-network-02.jpg'; // 管网巡检系统2
import productionNewspaper from './production-newspaper.jpg'; // 报装管理系统
// 智慧输水
import waterDeliveryGIS01 from './water-delivery-GIS-01.jpg'; // 管网地理信息系统1
import waterDeliveryGIS02 from './water-delivery-GIS-02.jpg'; // 管网地理信息系统2
import waterDeliveryGIS03 from './water-delivery-GIS-03.jpg'; // 管网地理信息系统3
import waterDeliveryGIS04 from './water-delivery-GIS-04.jpg'; // 管网地理信息系统4
import waterDeliveryDMA from './water-delivery-DMA.jpg'; // DMA漏损管理系统
import waterDeliveryPower from './water-delivery-power.jpg'; // 管网水力模型
// 智慧售水
import sellingWaterRevenue01 from './selling-water-revenue-01.jpg'; // 统一营收系统1
import sellingWaterRevenue02 from './selling-water-revenue-02.jpg'; // 统一营收系统2
import sellingWaterRevenue03 from './selling-water-revenue-03.jpg'; // 统一营收系统3
import sellingWaterTableService01 from './selling-water-table-service-01.jpg'; // 表务管理系统1
import sellingWaterTableService02 from './selling-water-table-service-02.jpg'; // 表务管理系统2
// 智慧客服
import serviceCustomerService from './service-customer-service.jpg'; // 客服热线系统
import serviceBusinessHall from './service-business-hall.jpg'; // 水务电子营业厅
// 智慧决策
import decisionMakingBigData from './decision-making-big-data.jpg'; // 水务大数据
// 智慧管理
import managementOA01 from './management-OA-01.jpg'; // OA办公自动化1
import managementOA02 from './management-OA-02.jpg'; // OA办公自动化2
import './index.scss';

const { SubMenu } = Menu;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      defaultSelectedKeys: this.props.location.state ? this.props.location.state.defaultSelectedKeys : '1-1',
      defaultOpenKeys: this.props.location.state ? this.props.location.state.defaultOpenKeys : 'sub1'
    };
  }

  handleClick = e => {
    this.setState({
      defaultSelectedKeys: e.key,
      defaultOpenKeys: e.keyPath[1]
    })
  };

  render() {
    const { defaultSelectedKeys, defaultOpenKeys } = this.state;

    return (
      <div className="solution-detail">
        <div className="solutionOfBanner">
          <img style={{ width: '100%', marginTop: '7vh' }} src={SolutionOfBanner} alt="" />
        </div>
        <div className="solution-detail-container">
          <Row>
            <Col span={6}>
              <div>
                <Menu
                  onClick={this.handleClick}
                  style={{ width: '100%' }}
                  defaultSelectedKeys={[defaultSelectedKeys]}
                  defaultOpenKeys={[defaultOpenKeys]}
                  mode="inline"
                >
                  <SubMenu
                    key="sub1"
                    title={
                      <span>智慧生产</span>
                    }
                  >
                    <Menu.Item key="1-1">生产调度系统</Menu.Item>
                    <Menu.Item key="1-2">水质监测系统</Menu.Item>
                    <Menu.Item key="1-3">二次加压集成系统</Menu.Item>
                    <Menu.Item key="1-4">管网巡检系统</Menu.Item>
                    <Menu.Item key="1-5">报装管理系统</Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub2"
                    title={
                      <span>智慧输水</span>
                    }
                  >
                    <Menu.Item key="2-1">管网地理信息系统</Menu.Item>
                    <Menu.Item key="2-2">DMA漏损管理</Menu.Item>
                    <Menu.Item key="2-3">管网水力模型</Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub3"
                    title={
                      <span>智慧售水</span>
                    }
                  >
                    <Menu.Item key="3-1">统一营收系统</Menu.Item>
                    <Menu.Item key="3-2">表务管理系统</Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub4"
                    title={
                      <span>智慧服务</span>
                    }
                  >
                    <Menu.Item key="4-1">客服热线系统</Menu.Item>
                    <Menu.Item key="4-2">水务电子营业厅</Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub5"
                    title={
                      <span>智慧决策</span>
                    }
                  >
                    <Menu.Item key="5-1">水务大数据</Menu.Item>
                  </SubMenu>
                  <SubMenu
                    key="sub6"
                    title={
                      <span>智慧管理</span>
                    }
                  >
                    <Menu.Item key="6-1">OA办公自动化</Menu.Item>
                  </SubMenu>
                </Menu>
              </div>
            </Col>
            <Col span={18}>
              <div className="solution-detail-info">
                <div className="solution-detail-info-title">
                  {defaultSelectedKeys === '1-1' && '生产调度系统'}
                  {defaultSelectedKeys === '1-2' && '水质监测系统'}
                  {defaultSelectedKeys === '1-3' && '二次加压集成系统'}
                  {defaultSelectedKeys === '1-4' && '管网巡检系统'}
                  {defaultSelectedKeys === '1-5' && '报装管理系统'}
                  {defaultSelectedKeys === '2-1' && '管网地理信息系统'}
                  {defaultSelectedKeys === '2-2' && 'DMA漏损管理系统'}
                  {defaultSelectedKeys === '2-3' && '管网水力模型'}
                  {defaultSelectedKeys === '3-1' && '统一营收系统'}
                  {defaultSelectedKeys === '3-2' && '表务管理系统'}
                  {defaultSelectedKeys === '4-1' && '客服热线系统'}
                  {defaultSelectedKeys === '4-2' && '水务电子营业厅'}
                  {defaultSelectedKeys === '5-1' && '水务大数据'}
                  {defaultSelectedKeys === '6-1' && 'OA办公自动化'}
                </div>
                {defaultSelectedKeys === '1-1' && <div className="solution-detail-info-txt">城市供水业务紧密围绕管网开展，供水调度、管网GIS管理、计量抄收、管网维护、规划设计、客户服务等都是与管网密切相关的。对管网的管理不应只停留在对资料层面，而是应以管网地理信息系统为基础，将水厂生产、压力、水质、流量、二次供水等供水系统的诸多要素建立关联，将生产监控SCADA、GIS、调度和业务管理统一到一个综合性的系统平台，集数据采集、监控、分析、决策于一体的高度集成化信息系统，采集范围覆盖水厂所有工艺单体的运行数据，这是生产管理的中枢神经系统；具有报警消息推送和移动网页浏览功能。</div>}
                {defaultSelectedKeys === '1-2' && <div className="solution-detail-info-txt">系统有水质监测子站和信息管理中心两部分构成。监测子站内部包括采水部分、分析仪表、和计算机控制系统部分。信息管理中心主要是以数据处理分析为主。水质监测子站采用力控嵌入式HMI结合现场PLC与自动监测仪器实现了设备状态监控、污染因子监测、设备反向控制、系统报警等功能，并通过GPRS/CDMA网络向信息管理中心提供现场实时数据信息；信息管理中心由力控企业级实时历史数据库pSpace负责汇总、处理、分析来自所有水质监测站的实时数据，并将分析处理后的有价值的信息提供给环保管理部门。</div>}
                {defaultSelectedKeys === '1-3' && <div className="solution-detail-info-txt">通过建立二次供水调度管控平台，集中监控、统一管理调度，当遇到城市供水险情时，进行统一调度，平衡峰谷，发挥在城市供水管理中的调蓄作用，保障供水安全，提高供水企业的经济效益和社会效益。</div>}
                {defaultSelectedKeys === '1-4' && <div className="solution-detail-info-txt">日常巡检、 现场维修、应急处理、其它的移动应用及着重于业务管理的任务下达、人员调度指挥、 事务的监管；实现供水管道及附属设施巡检、养护、维修管理工作的网络化、流程化、规范化、智能化。</div>}
                {defaultSelectedKeys === '1-5' && <div className="solution-detail-info-txt">传统的自来水用户申请报装业务管理是完全基于手工管理，报装程中不便于跟踪、考核、监督和科学的管理，一旦自来水报装业务流程中任何一环节出现了问题，会直接影响到自来水公司对外服务形象；另一方面，很难将新装水表及时纳入自来水营业收费系统进行管理，容易造成大量的水量流失。因此，应用先进的计算机软件实现对自来水报装流程的管理成为水司的一项重要工作。</div>}
                {defaultSelectedKeys === '1-5' && <div className="solution-detail-info-txt">报装管理系统是智慧水务平台客户服务系统中的一个重要组成部分。系统采用了目前最先进的工作流平台，将繁琐的报装业务整合成规范的工作流程，使用者只需要在电脑上填写相关的表单，系统就会按照定义好的流程自动往下流转，上一级的审批者就会通过网络收到相关的资料，这样一来，系统实现了报装工程资料录入、查勘设计、出图会审、预决算、竣工验收、水表插页、资科归档等流程的动态管理，通过电子文档网上流转，实现了信息共享，大大的提高了工做率。</div>}
                {defaultSelectedKeys === '2-1' && <div className="solution-detail-info-txt">管网地理信息GIS系统解决方案充分考虑与其它系统的开放互联、多数据接口、以空间数据库为基础，集成多层次、多方面的安全保障体系。</div>}
                {defaultSelectedKeys === '2-1' && <div className="solution-detail-info-txt">系统结合二三维一体化技术、物联网技术和大数据挖掘技术等，实现了管网图形数据和属性数据的计算机录入、编辑；对管线及各种设施进行属性查询、空间定位以及定性、定量的统计、分析；对各类图形（包括管线的横断面图和纵断面图）及统计分析报表显示和输出；为爆管、漏水事故的抢修、维修提供关闸方案，派工单及用户停水通知单，从而实现管网的信息化管理，对供水规划、设计、调度、抢修和图籍资料的档案管理提供强有力的科学决策依据，为管网的优化调度奠定良好的基础，从而大大提高了管网管理工作的效率和质量。</div>}
                {defaultSelectedKeys === '2-1' && <div className="solution-detail-info-txt">通过系统的建设和应用，提高管网管理和养护能力、现场事故处理速度，使供水企业获得运营经济效益，实现管网管理的科学化、规范化和现代化。</div>}
                {defaultSelectedKeys === '2-2' && <div className="solution-detail-info-txt">漏损管理是一个涉及多部门、多系统的综合业务，包括管网部、调度中心、客服部和企业领导等，是一个水务企业领导统一指挥，多部门配合、多系统协作的过程。漏损管理系统，就是为水务企业提供统一的信息平台、业务流程，将分散的、局部的、重复的漏损管理工作，组织成有计划，重点问题优先解决，逐步推进的工作。</div>}
                {defaultSelectedKeys === '2-3' && <div className="solution-detail-info-txt">本系统综合了GIS系统的静态信息与SCADA、营收等动态信息，并结合用水量的预报、估算与分配，按水力学理论对城市供水管网系统进行水力建模与模拟计算，在线跟踪供水系统水力运行状态，实时计算模拟出所有管道的流量、压降、流速和水厂、用户节点的压力等水力信息，为供水系统科学调度与管理提供依据。并根据实时的数据，自动校验模型，实现进行模型分析与日常管理一体化。</div>}
                {defaultSelectedKeys === '3-1' && <div className="solution-detail-info-txt">随着城市的发展，城镇供水的规模不断扩大，传统的手工抄表，按户收费等落后经营方式已无法满足庞大而复杂的营业管理工作。</div>}
                {defaultSelectedKeys === '3-1' && <div className="solution-detail-info-txt">为了适应城市的发展和企业的需求，必须对营销管理系统进行了严谨的业务逻辑和科学的数据系统构架设计，使得系统具备了满足本行业的不同需求特点，真正实现了业务数据的自定义模式，表现出了深层次的通用特性。</div>}
                {defaultSelectedKeys === '3-1' && <div className="solution-detail-info-txt">营收系统是一套集客户管理、表具管理（机械表、IC卡表、远传表、大口径表）、财务管理、抄表结算、报表分析、表务管理等功能为一体的综合性收费管理系统。系统对所有数据进行多角度、多层次的记录、比较、分析，对管理决策提供支持。</div>}
                {defaultSelectedKeys === '3-1' && <div className="solution-detail-info-txt">营业管理系统应完整地覆盖了水司营业管理的全部过程，术语定义应科学规范，结构设计严谨精细，功能设计全面完整，可最大限度满足客户的自定义需求，具有很强的通用性。系统原则上应采用三层构架设计，可完全适应局域网和广域网的运行需要。系统运行资源开销很小，可有效保护客户的现有装备投资。</div>}
                {defaultSelectedKeys === '3-2' && <div className="solution-detail-info-txt">表务管理系统就是利用计算机技术将水表的全部生命周期管理起来，实现信息传递，从而提升工作效率，显示工作流程和办理状态及运行轨迹，资料的保存更完整，查阅快捷简便，管理更规范化、透明化。</div>}
                {defaultSelectedKeys === '3-2' && <div className="solution-detail-info-txt">系统应当实现水计量仪表从采购回来到水表报废整个生命中的每一个时间状态的行为和状态，包括水表采购，水表管理（入库，出库，报废，回收等），水表拆换（故障换表，定期换表，领表退表，完工输入，出错处理），报表统计分析等各项功能。通过表务系统，供水企业可以做到对每块水表情况了解，规避了人为因素造成的企业损失，在企业资源控制上收益明显。</div>}
                {defaultSelectedKeys === '4-1' && <div className="solution-detail-info-txt">客服热线系统是企业与客户沟通的有效渠道，通过统一的对外服务电话为客户提供咨询、报修、投诉、查询等多方面的服务，实现“一站式服务”管理。系统不仅具备卓越的话务功能，而且具备完善的业务处理功能，实现了与水司各种业务系统的对接，大大提高了为民服务的效率。</div>}
                {defaultSelectedKeys === '4-2' && <div className="solution-detail-info-txt">自来水网上营业厅为您提供水费查询、水费在线缴费、业务办理、费用查询、帐单查询、在线客服、故障申告、投诉咨询及业务介绍等全方位的电子自助服务平台。</div>}
                {defaultSelectedKeys === '5-1' && <div className="solution-detail-info-txt">以“数据+地图+业务”为中心，整合SCADA系统、供水管网、营收系统、客服系统的运行数据，通过供水数据建模分析、管网空间分析、水力学模型，以更加“智慧”的方式辅助水务企业进行管理和运维，保证可靠供水，降低产销差，提升运营管控能力，实现经济效益和社会效益的不断增长。</div>}
                {defaultSelectedKeys === '6-1' && <div className="solution-detail-info-txt">智慧OA旨在提供一个统一线上办公平台，融合移动办公、任务分配、任务发布、线上请假、采购流程、差旅申请、报销申请、流程审批、企业通讯管理、材料管理、公告发布、考勤、员工资料管理等功能，打造一站式全业务体验。全领域协同办公，以支撑企业战略规划，全方位提高企业执行力、运营效率和管控能力。借助OA协同办公系统，水司能够高效地对日常办公进行自动化流转和管理。</div>}
                <div className="solution-detail-info-title" style={{ margin: '20px 0 10px 0' }}>
                  框架图
              </div>
                <div className="solution-detail-info-img-box">
                  {defaultSelectedKeys === '1-1' && <img className="solution-detail-info-img" src={productionScheduling} alt="" />}
                  {defaultSelectedKeys === '1-2' && <img className="solution-detail-info-img" src={productionMonitor} alt="" />}
                  {defaultSelectedKeys === '1-3' && <img className="solution-detail-info-img" src={productionPressurize01} alt="" />}
                  {defaultSelectedKeys === '1-3' && <img className="solution-detail-info-img" src={productionPressurize02} alt="" />}
                  {defaultSelectedKeys === '1-4' && <img className="solution-detail-info-img" src={productionNetwork01} alt="" />}
                  {defaultSelectedKeys === '1-4' && <img className="solution-detail-info-img" src={productionNetwork02} alt="" />}
                  {defaultSelectedKeys === '1-5' && <img className="solution-detail-info-img" src={productionNewspaper} alt="" />}
                  {defaultSelectedKeys === '2-1' && <img className="solution-detail-info-img" src={waterDeliveryGIS01} alt="" />}
                  {defaultSelectedKeys === '2-1' && <img className="solution-detail-info-img" src={waterDeliveryGIS02} alt="" />}
                  {defaultSelectedKeys === '2-1' && <img className="solution-detail-info-img" src={waterDeliveryGIS03} alt="" />}
                  {defaultSelectedKeys === '2-1' && <img className="solution-detail-info-img" src={waterDeliveryGIS04} alt="" />}
                  {defaultSelectedKeys === '2-2' && <img className="solution-detail-info-img" src={waterDeliveryDMA} alt="" />}
                  {defaultSelectedKeys === '2-3' && <img className="solution-detail-info-img" src={waterDeliveryPower} alt="" />}
                  {defaultSelectedKeys === '3-1' && <img className="solution-detail-info-img" src={sellingWaterRevenue01} alt="" />}
                  {defaultSelectedKeys === '3-1' && <img className="solution-detail-info-img" src={sellingWaterRevenue02} alt="" />}
                  {defaultSelectedKeys === '3-1' && <img className="solution-detail-info-img" src={sellingWaterRevenue03} alt="" />}
                  {defaultSelectedKeys === '3-2' && <img className="solution-detail-info-img" src={sellingWaterTableService01} alt="" />}
                  {defaultSelectedKeys === '3-2' && <img className="solution-detail-info-img" src={sellingWaterTableService02} alt="" />}
                  {defaultSelectedKeys === '4-1' && <img className="solution-detail-info-img" src={serviceCustomerService} alt="" />}
                  {defaultSelectedKeys === '4-2' && <img className="solution-detail-info-img" src={serviceBusinessHall} alt="" />}
                  {defaultSelectedKeys === '5-1' && <img className="solution-detail-info-img" src={decisionMakingBigData} alt="" />}
                  {defaultSelectedKeys === '6-1' && <img className="solution-detail-info-img" src={managementOA01} alt="" />}
                  {defaultSelectedKeys === '6-1' && <img className="solution-detail-info-img" src={managementOA02} alt="" />}
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    )

  }

}

export default withRouter(Index);
