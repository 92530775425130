import React from 'react';
import { Divider, Row } from 'antd';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import newsOfBanner from './news-banner.jpg';
import News0 from './news0.png';
import News1 from './news1.png';
import News2 from './news2.jpg';
import News3 from './news3.jpg';
import News3Of2 from './news3-2.jpg';
import News4Of1 from './news4-1.jpg';
import News4Of2 from './news4-2.jpg';
import News4Of3 from './news4-3.jpg';
import News5Of1 from './news5-1.jpg';
import News5Of2 from './news5-2.jpg';
import News5Of3 from './news5-3.jpg';
import News6Of1 from './news6-1.jpg';
import { ids } from './constant';
import './index.scss';

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.match.params.id
    };
  }

  componentWillMount() {
    document.getElementById('root').scrollIntoView(true);//为ture返回顶部，false为底部
  }

  findIndex = () => {
    for (let i = 0; i < ids.length; i++) {
      if (ids[i] === this.props.match.params.id) {
        return i
      }
    }
  }

  prevDetail = () => {
    let index = this.findIndex() - 1
    this.props.history.push("/newsDetail/" + ids[index])
  }

  nextDetail = () => {
    let index = this.findIndex() + 1
    this.props.history.push("/newsDetail/" + ids[index])
  }

  render() {
    let index = this.findIndex()

    return (
      <div className="news">
        <div className="news-of-banner">
          <img style={{ width: '100%' }} src={newsOfBanner} alt="" />
        </div>
        <div className="news-detail-container">
          <div className="news-detail-title">
            {String(index) === '0' && '2020智慧水务行业现状及发展前景分析'}
            {String(index) === '1' && '水行业未来发展趋势'}
            {String(index) === '2' && '武汉水务集团与华为云共建智慧水务'}
            {String(index) === '3' && '江苏智慧水务研究院浅析AI技术在涉水领域的应用'}
            {String(index) === '4' && '2020年中国国际“互联网+” 创新创业大赛'}
            {String(index) === '5' && '2020江都“带移动支付的无线售水终端装置”'}
            {String(index) === '6' && '800万！扬州10位创业创新领军人才领到资助资金'}
          </div>
          <div className="news-detail-time">
            {String(index) === '0' && '2020-06-09  16:25'}
            {String(index) === '1' && '2020-06-03  17:29'}
            {String(index) === '2' && '2020-05-26  10:31'}
            {String(index) === '3' && '2020-05-26  08:23'}
            {String(index) === '4' && '2020-03-18  13:30'}
            {String(index) === '5' && '2020-10-30  15:45'}
            {String(index) === '6' && '2019-01-26  15:20'}
          </div>
          <div className="news-detail-line"></div>
          <div>
            {String(index) === '0' &&
              <div>
                <div className="news-detail-txt">
                  我国是一个干旱缺水的国家，淡水资源总量约占全球水资源的6%，次于巴西、俄罗斯、加拿大、美国和印度尼西亚，居世界第6位，但人均仅为世界平均水平的1/4、美国的1/5，是全球人均水资源最贫乏的国家之一。2018年，我国水资源总量为27462.5亿立方米，同比减少4.5%，人均水资源量2008.00立方米，为轻度缺水。水务行业的传统领域包括供水和污水处理两个子行业，行业发展程度与经济增长水平、人口数量及城市化进程等因素高度相关，近年来我国城市及县城供水量和污水处理量保持稳定增长的趋势，行业运营基础稳定。
                </div>
                <div className="news-detail-txt">
                  智慧水务是指通过互联网、特联网、无线网络和软硬件，聚合各类水务信息，为政府、企业、市民提供无处不在的信息化服务，实现全面、可感知的绿色智能水务。随着经济发展水平的提高，环境保护与资源集约利用的要求也越来越高。传统水务存在着浪费严重、效率较低等缺陷，需要发展智慧水务来更好的满足经济、社会发展的需求。2018年，我国水利信息自动采集和水利信息网络基本覆盖全国，水利日常工作基本实现计算机化，在线数据快速增长，应用系统数量迅速增加，水利信息化综合体系墓本形成。智慧水务在基础设施建设和水务信息化的基础上将获得快速发展。近年来，智慧水务已成为传统水务领域转型升级或业务拓展的重要方向。事实上，伴随着“水十条”等利好政策的落地，环境治理强调用效果说话，行业对精细化的管理需求增高，智慧水务已成为我国传统水务领域转型升级的重要方向，并由此释放出颇具想象空间的市场体量。
                </div>
                <div className="news-detail-txt">
                  随着市场竞争日益激烈，行业利润率逐步走低，水务企业出于成本控制考虑，在节能降耗、降低建设和运营成本等方面都更加精细化。而面对如此激烈的竞争，目前国内传统的水务行业正在借助“互联网+”，以及“物联网、云计算、大数据”等技术新趋势，向智慧水务转型，并快速发展。因此，市场上对相应智慧水务产品的需求，也将加速增长。“十三五”期间，中国水务企业面临着适应经济新常态的重大挑战，面对“生态文明建设”、“国企改革”等政策推进力度增强、“云物移大智”技术应用日益广泛、提高运营决策效率需求更加急迫的发展环境，信息化必然成为水务企业转型发展的有力工具，“智慧水务”建设势在必行。随着物联网、大数据、云计算及移动互联网等新技术不断融入传统行业的各个环节，新兴技术和智能工业的不断融合，智慧水务发展空间具有明显的前景。
                </div>
              </div>}
            {String(index) === '1' &&
              <div>
                <div className="news-detail-txt">
                  水是生命之源、生态之本、生产之要，水务是事关经济社会、国计民生的最基本公共服务行业之一。我国自启动市政公用事业市场化改革以来，水务行业经历了跨越式发展，尤其是近几年来，在“水十条”、中央环保督察、城市水质排名、河湖库湾长制、新环保法、环保税法、碧水保卫战、绿色金融等一系列政策措施推动下，水务行业已成为我国环保产业市场规模最大、商业模式最清晰、运营管理最成熟的细分领域。
                 </div>
                <div className="news-detail-txt">
                  目前我国水务行业正处于市场化改革阶段，水务行业具有企业数量众多、规模化不足、区域保护格局明显、行业集中度较低等特点。随着我国环保产业的快速发展，市场规模将在相当长的时期内不断扩大，水务行业市场化服务需求将逐步突出，行业将呈现以下发展趋势
                </div>
                <div className="news-detail-txt-subtitle">
                  一、水务产业链一体化趋势将继续推进
                </div>
                <div className="news-detail-txt">
                  纵观近年来国内水务企业的改革成果，以实现了供排水一体化经营的深圳、重庆等地效果最为显著，通过当地政府授予区域内的特许经营权，对当地水务资产进行整合，组建水务集团公司，使其集供排水一体化、管网一体化、城乡区域统筹、区域市场垄断几大独特优势于一身，最终成为地区最具实力的现代化综合性水务服务商，甚至在全国都具影响力的现代大型水务企业。
                </div>
                <div className="news-detail-txt-subtitle">
                  二、行业并购与整合将加剧
                </div>
                <div className="news-detail-txt">
                  目前水务行业集中度依然不高，具有企业数量众多、规模化不足、区域分散等特点，尚未形成标杆性的龙头企业，最大的水务集团其服务市场份额也不过5%。水务行业区域性较强，异地业务扩张难度较大，外延式扩张是更为便捷有效的途径，上市公司通过并购整合打破技术及地域限制，实现快速扩张。如国内水务龙头企业北控水务、首创股份、重庆水务，均经过了一系列并购与整合，实现了规模的扩张。
                </div>
                <div className="news-detail-txt-subtitle">
                  三、水务企业将顺应市场需求，实现政企分离、产权清晰
                </div>
                <div className="news-detail-txt">
                  理顺政府与水务集团的关系，是建立供排水一体化体制的前提。作为一项体制改革，其目标不应仅是国有资产的简单合并，而是通过资产重组，使水务集团能适应现代企业制度要求，真正成为“产权清晰，权责明确，政企分开，管理科学”的现代企业，并逐步形成现代企业的法人治理结构，能充分发挥水务公司董事会、监事会和经营管理层的各项职能和作用。
                </div>
                <div className="news-detail-txt-subtitle">
                  四、智慧水务市场潜力亟待释放
                </div>
                <div className="news-detail-txt">
                  智慧水务将海量水务信息进行及时分析与处理，并做出相应的处理结果辅助决策建议，以更加精细和动态的方式管理水务系统的整个生产、管理和服务流程，从而达到“智慧”的状态。随着物联网、大数据、云计算及移动互联网等新技术不断融入传统行业的各个环节，新兴技术和智能工业的不断融合，智慧水务行业发展具有明显的前景。
                </div>
                <div className="news-detail-txt-subtitle">
                  五、乡镇污水处理成蓝海
                </div>
                <div className="news-detail-txt">
                  目前，我国污水处理行业仍相对滞后。由于我国水污染和水资源短缺问题日益严重，近年来国家出台了一系列政策支持污水处理行业发展，加强污水处理设施建设。近年来我国城市污水处理能力得到快速提高，但与城市供水能力尚不匹配，城市污水处理率仍较低，因此我国仍需大力发展污水处理行业
                </div>
                <div className="news-detail-txt">
                  乡镇污水处理成蓝海，高速增长阶段即将开启。与城市和县城污水处理情况不同，我国乡镇地区经济较为落后，再加上环保意识不强，污水处理设施不完善，基础设施严重不足，使得乡镇污水处理率偏低。
                </div>
                <div className="news-detail-txt-subtitle">
                  六、水价将逐步上涨
                </div>
                <div className="news-detail-txt">
                  中国水价制度经历了公益性无偿供水、政策性低价供水、按成本核算计收水费、按商品价格管理四个阶段。总体来看，水价改革呈现价格不断上涨、价格分类不断简化、逐渐推行阶梯式水价等特征。
                </div>
                <div className="news-detail-txt">
                  2012年2月16日，国务院发布《关于实行最严格水资源管理制度的意见》，严格实行用水总量控制，到2030年全国用水总量控制在7000亿立方米以内。为此，《意见》提出将实行最严格水资源管理制度，严格水资源征收费和取水审批管理制度，这将为各地加快水价改革步伐指引方向，未来水价逐步上调将成定局。
                </div>
                <div className="news-detail-txt">
                  总而言之，在接下来的几年中，对水务行业将发生一系列变化。因此，国内水务企业应该未雨绸缪，迎接未来的挑战。
                </div>
              </div>}
            {String(index) === '2' &&
              <div>
                <div className="news-detail-txt">
                  管家式服务很多人都听过，可你听说过城市供水领域的管家式服务吗？随着5月18日，武汉市水务集团（以下简称武水集团）"客户服务平台"在汉阳、汉口两地同时上线调试运行，生活在武汉市的居民将可以率先体验这一服务。
              </div>
                <div className="news-detail-txt">
                  武水集团是一家具有一百多年历史的国有特大型企业，现拥有11座自来水厂、19座大型供水转压站和9000余公里供水管网，日综合供水能力428.7万吨，为武汉市约1593平方公里的区域提供着供水服务。
              </div>
                <div className="news-detail-txt">
                  城市供水是居民最基本的生活要素之一，也是居民美好生活的重要保障。2018年，武水集团提出由"传统水务"向"智慧水务"迈进的工作重点和奋斗目标，决定借助于人工智能、大数据、云计算等高新技术，全面提升水务工作的精细化和现代化管理水平，为武汉居民提供更好的供水服务。刚刚上线的客户服务平台便是其打造"智慧水务"的重要举措。
              </div>
                <div className="news-detail-txt">
                  与传统业务应用系统相比，武水集团客户服务平台依托华为云与计算的云技术，对报装、营收、热线、报修等分属不同部门的服务职能集中整合，重构业务流程，精简内部环节，极大提升了服务效能，有效提升用户体验满意度。
              </div>
                <div className="news-detail-txt">
                  对调度员、维修人员来说，从以往的四级调度机构缩减为两级，调度员可以从重复劳动中解放出来充实一线，维修人员也减少了等待派单的时间，工单流转速度明显提升。
              </div>
                <div className="news-detail-txt">
                  对用户来说，无论是咨询、报修或是办理业务，通过手机30分钟内就能获得精准服务。得益于武水集团官网、微信、支付宝及智能客服在云端融合对接，整个服务过程可视、可控、可追溯，让用户省心又安心。
              </div>
                <div className="news-detail-txt">
                  更让用户惊喜的是，智能客服还可以为用户提供管家式的贴心服务：其基于大数据收集、分析，可精准预测用户行为，从而及时提醒用户用水异常、水表换新，保障用户用水无忧。
              </div>
                <div className="news-detail-txt">
                  此外，武汉正处于全面复苏的重要关口，武水集团客户服务平台的上线将"有利于武汉市各类企业用水诉求的快速响应和督办落实"，从而推动各行各业复工复产，确保武汉城市经济复苏、社会稳定发展。
              </div>
                <div className="news-detail-txt">
                  事实上，武水集团客户服务平台的上线运行，也意味着武汉智慧水务"41111工程"首个项目正式落地应用。
              </div>
                <div className="news-detail-txt">
                  智慧水务"41111"工程，即四平台一中心、一图、一云、一网。四平台是调度指挥平台、客户服务平台、集团管控平台、综合保障平台；一中心是指数据中心；一图是实现不同业务场景的业务一张图；一云是指基于公有云和私有云实现一朵混合云；一网是指全集团使用统一的一张大网络。
              </div>
                <div className="news-detail-txt">
                  进入"新基建"时代，发力以数字化为目标的新型基础设施布局正当其时。"新基建"的核心是联接与计算，5G、云、AI则构成了"新基建"三要素。基于华为30多年的ICT基础设施建设经验与行业数字化转型实践，华为云与计算能够为新型基础设施打造最强大引擎，是政企客户在"新基建"时代的最佳合作伙伴。
              </div>
                <div className="news-detail-txt">
                  水是生命之源，武汉也是一座因水而兴的城市。通过携手华为云与计算打造客户服务平台，武水集团已成为"新基建"智慧政务领域的智能先行者。未来，华为云与计算将继续帮助武水集团推动智慧水务"41111"工程，共同助推武汉市公共事业加速完成数字化转型，进而推动武汉这座城市再次兴盛，共享"新基建"时代红利。
              </div>
              </div>}
            {String(index) === '3' &&
              <div>
                <div className="news-detail-txt">
                  随着科学技术的发展，在数字经济环境下，越来越多的新兴技术被应用到传统水务行业，推动着“智慧水务”向更高阶段发展。目前最受关注的十大技术主要包括云计算、物联网、大数据、移动互联网、实时动态监测、BIM建筑信息管理、GIS地理信息系统、3D打印、VR虚拟现实和AI人工智能技术。下面我们来一起了解一下AI技术如何应用到水务行业，并提升水务管理工作的智慧化程度。
                </div>
                <div className="news-detail-txt">
                  我们首先了解一下什么是AI技术。AI就是人工智能，它是研究、开发用于模拟、延伸和扩展人的智能的理论、方法、技术及应用系统的一门新的技术科学。人工智能是计算机科学的一个分支，它企图了解智能的实质，并生产出一种新的能以人类智能相似的方式做出反应的智能机器，该领域的研究包括机器人、语言识别、图像识别、自然语言处理和专家系统等。
               </div>
                <div className="news-detail-txt">
                  AI技术的应用。人工智能系统具有自学习、推理、判断和自适应能力，主要应用在优化设计、故障诊断、智能检测、系统管理等领域，人工智能可以帮助我们解决图像识别，语音识别，信号处理，理解变量之间的关系，发现数据中的不规则模式等等。也就是人工智能的发展，已经能让计算机的算力从定量的基础上，到达了定性分析的能力，而且是可以自动化运行的，这一点非常强大，结合到各行各业，人工智能的应用也各不相同。
                </div>
                <div className="news-detail-txt">
                  就拿水务这块来说，我们可以通过机器学习，结合往年十几年几十年的情形，得出一台河道水流量变化的模式，可以预测水位高峰，对于防汛抗旱能起到支持作用；对于自来水厂而言，我们通过人工智能技术，对水质进行自动化评价，给水质打分，提升我们优化水质的空间，为全城居民提供更好的用水质量；人工智能技术还可以检测预测全城的自来水管运行状态，提前防止可能出现的水管爆裂；在水质去污的工艺处理上，我们还能借助机器学习的手段，帮我们降本增效。
              </div>
                <div className="news-detail-txt">
                  江苏智慧水务研究院专注于传统水务行业的转型升级，通过科技化手段提升水务管理的智慧化，形成跨行业、跨领域、跨地区的管水大数据组织、分析、存储、共享、自我学习技术，打造集智能感知、智能仿真、智能诊断、智能预报、智能调度、智能控制、智能处置和智能评价等功能于一体的智慧化节水管理应用平台。
                  </div>
              </div>}
            {String(index) === '4' &&
              <div>
                <div className="news-detail-txt">
                  为全面落实习近平总书记给中国“互联网+”大学生创新创业大赛“青年红色筑梦之旅”大学生的重要回信精神，深入推进大众创业万众创新，引领创新创业教育国际交流合作，加快培养创新创业人才，促进创新驱动创业、创业引领就业，在2020年举办第六届中国国际“互联网+”大学生创新创业大赛。我公司总经理戴俊先生以及其小组研究成员参加了江苏省赛道，本届大赛主题是“我敢闯、我会创”！
                  </div>
                <div className="news-detail-txt">
                  以赛促教，探索素质教育新途径。总经理戴俊及小组成员以恒沁科技为题，以智慧水务为创新重点，进行了一系列的探索研究，科研攻关。
                  </div>
                <div className="news-detail-txt">
                  以赛促创，搭建成果转化新平台。推动赛事成果转化和产学研用紧密结合，促进“互联网+”新业态形成，服务经济高质量发展，他们制作的课题“恒沁科技——智慧水务”最终获得所有评委的认可，进入决赛圈，获得了第二名的好成绩。
                  </div>
              </div>}
            {String(index) === '5' &&
              <div>
                <div className="news-detail-txt">
                  目前市面上的售水终端设备大多数是用户手持水卡在柜台上进行人工缴费，既浪费时间成本，又耗费人力成本。基于以上问题，本公司研发了基于移动支付的无线售水终端设备，该终端可以使用微信或支付宝等民众常用付款方式进行手机网上缴费，方便用户随时随地的在线查看用水情况与费用使用情况。与传统设备相比，该设备在稳定性、经济性及其他方面均具有很大的优势。
                </div>
                <div className="news-detail-txt">
                  2020年10月底，在江都举办了扬州大学与恒沁科技校企合作项目报告，报告中提出了“带移动支付的无线售水终端装置。
                </div>
              </div>}
            {String(index) === '6' &&
              <div>
                <div className="news-detail-txt">
                  今天下午，第二届“智汇开发区·高层次人才洽谈会”在迎宾馆举行。中国科学院院士、南京大学物理系教授都有为，以及来自吉林大学、大连理工大学、华南理工大学、新加坡太阳能研究所、中科院计算所等多所高校院所的专家教授参加会议。会上，扬州经济技术开发区对10名高层次创业创新领军人才发放项目资助资金，资助金额共计800万元。
                </div>
                <div className="news-detail-txt">
                  据介绍，为了落实“人才强区”战略，开发区落实了《关于进一步加快人才集聚培养推进人才强区战略的实施意见》，组织高层次创业创新领军人才评选。经单位推荐、资格审查和综合评审等环节，确定资助了10名领军人才。这10名高层次创业创新领军人才是：扬州国扬电子有限公司龚大卫、江苏汉信数据科技有限公司顾进广、扬州美德莱医疗用品有限公司康传清、扬州盛世云信息科技有限公司谢巍、三书（扬州）信息科技有限公司李明亮、江苏恒沁科技有限公司戴俊、扬州荣德新能源科技有限公司席珍强、扬州恒新座椅有限公司白先旭、扬州南方园林设计院有限公司王伟、江苏高泰软件技术有限公司鲍宇。
                </div>
                <div className="news-detail-txt">
                  “扬州经济技术开发区是一块创新创业的热土，在这块热土上，我和我的团队攻坚克难，创造了很多的不可能。”扬州国扬电子有限公司龚大卫博士激动地说，“2017年，扬州国扬电子有限公司邀请我加入，我二话不说就来了。短短一年多时间内，我和我的团队克服了工艺技术、材料技术和制造环节中遇到的种种困难，在极短的时间里建立了国内领先的芯片技术平台，成功研发了两代1GB器件芯片，器件在性能，可靠性和参数致性等方面都达到了国际一流公司的同类产品水一平。”
                </div>
                <div className="news-detail-txt">
                  “近几年，开发区构建务实有效的人才政策体系，引进了一大批高层次创新人才和领军人物。”开发区相关人士介绍，2017年，开发区出台《关于进一步加快人才集聚培养推进人才强区战略的实施意见》，围绕四大产业、三大板块，大力实施顶尖科学家引进计划、创业创新领军人才引进计划、优秀博士人才引进计划。2017、2018年资助21个高层次人才创业创新人才，资助金额总计1450万元；2018年引进1名顶尖人才—中科院长春光机所王立军院士，给予1000万元项目资助、2000万元股权投资和免费创业场所。 同时，2018年出台《扬州经济技术开发区企业引进人才住房保障实施办法》，分别给予硕士、博士、市级领军人才、省级领军人才、国家级领军人才、顶尖人才8万元、18万元、25万元、35万元、60万元和200万元购房补贴；分别给予本科、硕士、博士每人每月600-4000元租房补贴；购房补贴力度全市最大。
                </div>
                <div className="news-detail-txt">
                  现场进行了项目签约，共签约三批校地校企合作，技术开发合作，人才引进共15个项目。
                </div>
              </div>}
            <div className="news-detail-img-box">
              {String(index) === '0' && <img src={News0} alt="" />}
              {String(index) === '1' && <img src={News1} alt="" />}
              {String(index) === '2' && <img src={News2} alt="" />}
              <Row>
                {String(index) === '3' && <img src={News3} alt="" />}
              </Row>
              <Row>
                {String(index) === '3' && <img src={News3Of2} alt="" />}
              </Row>
              <Row>
                {String(index) === '4' && <img src={News4Of1} alt="" />}
              </Row>
              <Row>
                {String(index) === '4' && <img src={News4Of2} alt="" />}
              </Row>
              <Row>
                {String(index) === '4' && <img src={News4Of3} alt="" />}
              </Row>
              <Row>
                {String(index) === '5' && <img src={News5Of1} alt="" />}
              </Row>
              <Row>
                {String(index) === '5' && <img src={News5Of2} alt="" />}
              </Row>
              <Row>
                {String(index) === '5' && <img src={News5Of3} alt="" />}
              </Row>
                {String(index) === '6' && <img src={News6Of1} alt="" />}
            </div>
          </div>
          <div className="news-detail-line"></div>
          <div className="news-detail-nav">
            {index > 0 && <Link onClick={this.prevDetail}>上一篇</Link>}
            <Divider className="news-detail-nav-divider" type="vertical" />
            {index < 6 && <Link onClick={this.nextDetail}>下一篇</Link>}
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Index);
