import React from 'react';
import { Row, Col, Card } from 'antd';
import { Link } from 'react-router-dom';
import homeNewsImg1 from './home-news-img1.jpg';
import homeNewsImg2 from './home-news-img2.jpg';
import homeNewsImg3 from './home-news-img3.jpg';
import { ids } from '../../news/constant';
import './index.scss';

const { Meta } = Card;

const relatedCasesCard = [
  {
    id: ids[4],
    relatedCasesImg: homeNewsImg1,
    relatedCasesTitle: '2020年中国国际“互联网+” 创新创业大赛',
    relatedCasesDescription: '为全面落实习近平总书记给中国“互联网+”大学生创新创业大赛“青年红色筑梦之旅”大学生的重要回信精神，深入推进大众创业万众创新，引领创新创业教育国际交流合作，加快培养创新创业人才，促进创新驱动创业、创业引领就业，在2020年举办第六届中国国际“互联网+”大学生创新创业大赛。我公司总经理戴俊先生以及其小组研究成员参加了江苏省赛道，本届大赛主题是“我敢闯、我会创”！'
  },
  {
    id: ids[5],
    relatedCasesImg: homeNewsImg2,
    relatedCasesTitle: '2020江都“带移动支付的无线售水终端装置”',
    relatedCasesDescription: '目前市面上的售水终端设备大多数是用户手持水卡在柜台上进行人工缴费，既浪费时间成本，又耗费人力成本。基于以上问题，本公司研发了基于移动支付的无线售水终端设备，该终端可以使用微信或支付宝等民众常用付款方式进行手机网上缴费，方便用户随时随地的在线查看用水情况与费用使用情况。与传统设备相比，该设备在稳定性、经济性及其他方面均具有很大的优势。'
  },
  {
    id: ids[6],
    relatedCasesImg: homeNewsImg3,
    relatedCasesTitle: '800万！扬州10位创业创新领军人才领到资助资金',
    relatedCasesDescription: '今天下午，第二届“智汇开发区·高层次人才洽谈会”在迎宾馆举行。中国科学院院士、南京大学物理系教授都有为，以及来自吉林大学、大连理工大学、华南理工大学、新加坡太阳能研究所、中科院计算所等多所高校院所的专家教授参加会议。会上，扬州经济技术开发区对10名高层次创业创新领军人才发放项目资助资金，资助金额共计800万元。'
  }
];

class Index extends React.Component {
  render() {
    return (
      <div className="home-news">
        <div className="home-news-title">新闻动态</div>
        <div>
          <Row style={{ margin: '1% 15%' }}>
            {relatedCasesCard.map((item, index) => {
              return (
                <Col span={8} data-aos="fade-up" key={index}>
                  <Link to={"/newsDetail/" + item.id}>
                    <Card
                      hoverable
                      className="home-news-card"
                      cover={<img style={{ height: '18vh', width: '100%' }} alt="example" src={item.relatedCasesImg} />}
                    >
                      <Meta
                        className="home-news-card-info"
                        title={item.relatedCasesTitle}
                        description={item.relatedCasesDescription}
                      />
                    </Card>
                  </Link>
                </Col>
              );
            })}
          </Row>
        </div>
      </div>
    );
  }
}

export default Index;
