import React from 'react';
import { useCountUp } from 'react-countup';

const Index = () => {
	const { countUp, start } = useCountUp({
		start: 0,
		end: 617527,
		delay: 1000,
		duration: 3
	});

	let onScroll = () => {
    let scrolled =  window.pageYOffset ;
		if (scrolled <= 1000) {
      start();
		}
	};
	window.addEventListener('scroll', onScroll);
	return (
		<div >
			<div>{countUp}</div>
		</div>
	);
};

export default Index;
