import React from 'react';
import { Row, Col } from 'antd';
import Underline from './underline2.png';
import CountUpNumOfUser from './components/CountUpNumOfUser';
import CountUpNumOfTotalOrders from './components/CountUpNumOfTotalOrders';
import CountUpNumOfTotalOrderAmount from './components/CountUpNumOfTotalOrderAmount';
import './index.scss';

class Index extends React.Component {
	render() {
		return (
			<div className="homeNum">
				<div className="homeNumTitle" data-aos="fade-up">
					可靠的数据支持
				</div>
				<div className="homeNumUnderline">
					<img src={Underline} alt="" style={{ width: '332px' }} data-aos="zoom-in" />
				</div>
				<div className="homeNumNum">
					<Row>
						<Col span={6} data-aos="fade-up">
							<div>
								<div className="NumNum">
									<div>
										<CountUpNumOfUser />
									</div>
								</div>
								<div className="NumSuffix">户</div>
								<div className="NumPrefix">服务总用户数</div>
							</div>
						</Col>
						<Col span={8} data-aos="fade-up">
							<div>
								<div className="NumNum">
									<CountUpNumOfTotalOrders />
								</div>
								<div className="NumSuffix">笔</div>
								<div className="NumPrefix">服务订单总数</div>
							</div>
						</Col>
						<Col span={8} offset={2} data-aos="fade-up">
							<div>
								<div className="NumNum">
									<CountUpNumOfTotalOrderAmount />
								</div>
								<div className="NumSuffix">元</div>
								<div className="NumPrefix">服务订单总金额</div>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		);
	}
}

export default Index;
