import React from 'react';
import { Row, Col, Card } from 'antd';
import relatedCasesOfBanner from './related-cases-of-banner.jpg';
import RelatedCases1 from './related-cases1.jpg';
import RelatedCases2 from './related-cases2.jpg';
import RelatedCases3 from './related-cases3.jpg';
import RelatedCases4 from './related-cases4.jpg';
import RelatedCases5 from './related-cases5.jpg';
import RelatedCases6 from './related-cases6.jpg';
import './index.scss'

const { Meta } = Card;

const relatedCasesCard1 = [
	{
		relatedCasesImg: RelatedCases1,
		relatedCasesDescription: '通辽自来水缴费项目'
	},
	{
		relatedCasesImg: RelatedCases2,
		relatedCasesDescription: '秦皇岛北戴河首创制水有限责任'
	},
	{
		relatedCasesImg: RelatedCases3,
		relatedCasesDescription: '长白机械有限公司给水改造'
	}
];

const relatedCasesCard2 = [
	{
		relatedCasesImg: RelatedCases4,
		relatedCasesDescription: '中条山有色金属有限公司'
	},
	{
		relatedCasesImg: RelatedCases5,
		relatedCasesDescription: '鄂温克旗智慧水务项目'
	},
	{
		relatedCasesImg: RelatedCases6,
		relatedCasesDescription: 'DMA分区管理系统'
	}
];

class Index extends React.Component {
	componentWillMount() {
		document.getElementById('root').scrollIntoView(true); //为ture返回顶部，false为底部
	}

	render() {
		return (
			<div className="relatedCases">
				<div className="relatedCasesOfBanner">
					<img style={{ width: '100%', marginTop: '7vh' }} src={relatedCasesOfBanner} alt="" />
				</div>
				<div className="relatedCasesContent">
					<Row style={{ margin: '5vh 15vw' }}>
						{relatedCasesCard1.map((item) => {
							return (
								<Col span={8} data-aos="fade-up">
									<Card
										hoverable
										style={{ width: '80%' }}
										cover={
											<img style={{ height: '18vh' }} alt="example" src={item.relatedCasesImg} />
										}
									>
										<Meta description={item.relatedCasesDescription} />
									</Card>
								</Col>
							);
						})}
					</Row>
					<Row style={{ margin: '5vh 15vw' }}>
						{relatedCasesCard2.map((item) => {
							return (
								<Col span={8} data-aos="fade-up">
									<Card
										hoverable
										style={{ width: '80%' }}
										cover={
											<img style={{ height: '18vh' }} alt="example" src={item.relatedCasesImg} />
										}
									>
										<Meta description={item.relatedCasesDescription} />
									</Card>
								</Col>
							);
						})}
					</Row>
				</div>
			</div>
		);
	}
}

export default Index;
