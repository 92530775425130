import React from 'react';
import { Row, Col, Divider } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import 'antd/dist/antd.css';
import './index.scss';
import { createFromIconfontCN } from '@ant-design/icons';

const IconFont = createFromIconfontCN({
  scriptUrl: ['//at.alicdn.com/t/font_616089_y9a9augyte.js']
});

class Index extends React.Component {
  render() {
    return (
      <div style={{ width: '100%' }}>
        <div style={{ background: '#26252a' }}>
          <Row>
            <Col span={10} offset={3}>
              <div className="footer-box" style={{ marginTop: 40 }}>
                <Link to="/" className="footer-box-link">首页</Link>&nbsp;&nbsp;
								<Divider type="vertical" />
                <span>&nbsp;&nbsp;<Link to="/solution" className="footer-box-link">解决方案</Link>&nbsp;&nbsp;</span>
                <Divider type="vertical" />
                <span>&nbsp;&nbsp;<Link to="/relatedCases" className="footer-box-link">相关案例</Link>&nbsp;&nbsp;</span>
                <Divider type="vertical" />
                <span>&nbsp;&nbsp;<Link to="/news" className="footer-box-link">新闻资讯</Link>&nbsp;&nbsp;</span>
                <Divider type="vertical" />
                <span>&nbsp;&nbsp;<Link to="/about" className="footer-box-link">关于我们</Link>&nbsp;&nbsp;</span>
                {/* <div style={{ marginTop: 24 }}>友情链接：</div> */}
              </div>
            </Col>
            <Col span={11}>
              <div className="footer-box">
                <div>
                  <p className="footer-detail footer-move">
                    <IconFont type="icon-region" />&nbsp;&nbsp;地址：江苏省扬州市智谷科技综合体B座1812室
									</p>
                  <p className="footer-detail footer-move">
                    <IconFont type="icon-email" />&nbsp;&nbsp;邮箱：hqkj@everqin.com
									</p>
                  <p className="footer-detail footer-move">
                    <IconFont type="icon-phonecall" />&nbsp;&nbsp;电话: 0514-87151138
									</p>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <div
          style={{
            background: '#212025',
            color: '#73777a',
            height: '60px',
            lineHeight: '60px',
            fontSize: '14px'
          }}
        >
          Copyright © 2020 www.everqin.com 江苏恒沁科技有限公司 All rights reserved. 苏ICP备18059877号-1
				</div>
      </div>
    );
  }
}

export default Index;
