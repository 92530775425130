import Index from '../views/home';
import News from '../views/news';
import NewsDetail from '../views/news/newsDetail';
import RelatedCases from '../views/relatedCases';
import About from '../views/about';
import Solution from '../views/solution';
import SolutionDetail from '../views/solution/solutionDetail';

export const routers = [
  { path: '/', component: Index },
  { path: '/news', component: News },
  { path: '/newsDetail/:id', component: NewsDetail },
  { path: '/relatedCases', component: RelatedCases },
  { path: '/about', component: About },
  { path: '/solution', component: Solution },
  { path: '/solutionDetail', component: SolutionDetail }
];
