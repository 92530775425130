import React from 'react';
import BigDataOperation from './big-data-operation.png';
import BigDataForecast from './big-data-forecast.png';
import BigDataVisualization from './big-data-operation-visualization.png';
import BigDataCommand from './big-data-operation-command.png';
import './index.scss';

function Index() {
  return (
    <div className="big-data">
      <div className="big-data-title">大数据的应用</div>
        <div className="big-data-box">
            <div className="big-data-box-item" data-aos="fade-up">
              <div className="big-data-box-item-img-box">
                <img
                  className="big-data-box-item-img"
                  src={BigDataOperation}
                  alt=""
                />
              </div>
              <div className="big-data-box-item-title">业务运营分析</div>
              <div className="big-data-box-item-info">给水管网水质分析</div>
              <div className="big-data-box-item-info">给水管网水力分析</div>
              <div className="big-data-box-item-info">流量分析</div>
              <div className="big-data-box-item-info">用户分析</div>
              <div className="big-data-box-item-info">管网漏损率分析</div>
              <div className="big-data-box-item-info">&nbsp;</div>
              <div className="big-data-box-item-more">了解更多{'>'}</div>
            </div>
            <div className="big-data-box-item" data-aos="fade-up">
              <div className="big-data-box-item-img-box">
                <img
                  className="big-data-box-item-img"
                  src={BigDataForecast}
                  alt=""
                />
              </div>
              <div className="big-data-box-item-title">预测分析</div>
              <div className="big-data-box-item-info">用水量预测</div>
              <div className="big-data-box-item-info">曝管可能性预测</div>
              <div className="big-data-box-item-info">设备故障预测</div>
              <div className="big-data-box-item-info">&nbsp;</div>
              <div className="big-data-box-item-info">&nbsp;</div>
              <div className="big-data-box-item-info">&nbsp;</div>
              <div className="big-data-box-item-more">了解更多{'>'}</div>
            </div>
            <div className="big-data-box-item" data-aos="fade-up">
              <div className="big-data-box-item-img-box">
                <img
                  className="big-data-box-item-img"
                  src={BigDataVisualization}
                  alt=""
                />
              </div>
              <div className="big-data-box-item-title">实时监测的可视化</div>
              <div className="big-data-box-item-info">实时流量可视化</div>
              <div className="big-data-box-item-info">实时水质可视化</div>
              <div className="big-data-box-item-info">实时水压可视化</div>
              <div className="big-data-box-item-info">设备状态可视化</div>
              <div className="big-data-box-item-info">用户交费可视化</div>
              <div className="big-data-box-item-info">投诉处理可视化</div>
              <div className="big-data-box-item-more">了解更多{'>'}</div>
            </div>
            <div className="big-data-box-item" data-aos="fade-up">
              <div className="big-data-box-item-img-box">
                <img
                  className="big-data-box-item-img"
                  src={BigDataCommand}
                  alt=""
                />
              </div>
              <div className="big-data-box-item-title">决策指挥</div>
              <div className="big-data-box-item-info">管网抢修决策</div>
              <div className="big-data-box-item-info">水质污染决策</div>
              <div className="big-data-box-item-info">&nbsp;</div>
              <div className="big-data-box-item-info">&nbsp;</div>
              <div className="big-data-box-item-info">&nbsp;</div>
              <div className="big-data-box-item-info">&nbsp;</div>
              <div className="big-data-box-item-more">了解更多{'>'}</div>
            </div>
      </div>

    </div>
  );
}

export default Index;
