import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import productsImg1 from './center_01.jpg';
import productsImg2 from './center_02.jpg';
import productsImg3 from './center_03.jpg';
import productsImg4 from './center_04.jpg';
import productsLine from './center_05.jpg';
import './index.scss';

function Index() {
  return (
    <div className="home-recommendation-information">
      <div className="home-recommendation-information-title">我们的产品</div>
      <div className="home-recommendation-information-box">
        <Row>
          <Col span={6}><div data-aos="zoom-in"><img className="products-img" src={productsImg1} alt="" /></div></Col>
          <Col span={6}>
            <Link to={{ pathname: '/solutionDetail', state: { defaultOpenKeys: 'sub3', defaultSelectedKeys: '3-1' } }}>
              <div className="products-info-box" data-aos="zoom-in">
                <div><img className="products-line" src={productsLine} alt=""/></div>
                <div className="products-title">智慧水务营收客服整体解决方案</div>
                <div className="products-info">供水客户的所有业务数据有机的进行关联、分析和展示，为客户提供更加便捷的服务，让供水客户感受到智慧水务带给他们的便捷和更加高效的服务。以更加“智慧”的方式辅助水务企业进行管理和运维，从而保证可靠供水，降低产销差，提升运营管控能力，实现效益的不断增长。</div>
              </div>
            </Link>
          </Col>
          <Col span={6}><div data-aos="zoom-in"><img className="products-img" src={productsImg2} alt="" /></div></Col>
          <Col span={6}>
            <Link to={{ pathname: '/solutionDetail', state: { defaultOpenKeys: 'sub1', defaultSelectedKeys: '1-1' } }}>
              <div className="products-info-box" data-aos="zoom-in">
                <div><img className="products-line" src={productsLine} alt="" /></div>
                <div className="products-title">智慧水厂一体化解决方案</div>
                <div className="products-info">实时监控分析水厂耗能指标，并对水厂生产经营决策、管理、计划、调度、过程优化、故障诊断、数据建模分析等进行综合处理。水厂自动化生产管理系统主要采用先进自动控制方案解决水厂无人或少人的制水生产过程控制。为规范化管理、节能降耗管理提供技术支持。</div>
              </div>
            </Link>
          </Col>
        </Row>
        <Row>
          <Col span={6}>
            <Link to={{ pathname: '/solutionDetail', state: { defaultOpenKeys: 'sub1', defaultSelectedKeys: '1-3' } }}>
              <div className="products-info-box" data-aos="zoom-in">
                <div><img className="products-line" src={productsLine} alt="" /></div>
                <div className="products-title">二次供水高效运维管理解决方案</div>
                <div className="products-info">将供水客户的所有业务数据有机的进行关联、分析和展示，为客户提供更加便捷的服务，让供水客户感受到智慧水务带给他们的便捷和更加高效的服务。以更加“智慧”的方式辅助水务企业进行管理和运维，从而保证可靠供水，降低产销差，提升运营管控能力，实现经济效益和社会效益的不断增长，提供有力支撑。</div>
              </div>
            </Link>
          </Col>
          <Col span={6}><div data-aos="zoom-in"><img className="products-img" src={productsImg3} alt="" /></div></Col>
          <Col span={6}>
            <Link to={{ pathname: '/solutionDetail', state: { defaultOpenKeys: 'sub2', defaultSelectedKeys: '2-2' } }}>
              <div className="products-info-box" data-aos="zoom-in">
                <div><img className="products-line" src={productsLine} alt="" /></div>
                <div className="products-title">供水漏损管理解决方案</div>
                <div className="products-info">管网漏损控制策略和DMA管理方法研发，为国内供水量身定制的漏损数据分析与决策支持软件。系统基于管网GIS、大数据分析，主要应用各种漏损计算方法，对计量分区和DMA进行管理，计算区域漏损水量、区域产销差水量、水量平衡表，从不同空间尺度、不同时间尺度分析全管网和各级统计分析分区的漏损和产销差现状。</div>
              </div>
            </Link>
          </Col>
          <Col span={6}><div data-aos="zoom-in"><img className="products-img" src={productsImg4} alt="" /></div></Col>
        </Row>
      </div>
    </div>
  );
}

export default Index;
