import React from 'react';
import { Route, HashRouter, Switch } from 'react-router-dom';
import { routers } from './routers';
import ScrollToTop from './scrollToTop';
import Nav from '../components/Nav';
import HomeFooter from '../components/Footer';
import Logo from '../assets/img/logo.png';
import { Layout } from 'antd';
import 'antd/dist/antd.css';

const { Header, Footer, Content } = Layout;

class RouterConfig extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parentCurrent: 'home'
    };
  }

  componentWillUpdate() {
    document.getElementById('root').scrollIntoView(true); //为ture返回顶部，false为底部
  }

  render() {
    return (
      <HashRouter>
        <ScrollToTop>
          <Layout>
            <Header
              style={{
                position: 'fixed',
                height: '7vh',
                width: '100%',
                margin: 0,
                padding: '0 0 0 50%',
                background: '#fff',
                overflow: 'hidden',
                zIndex: 2
              }}
            >
              <div>
                <div className="logoImg">
                  <img
                    style={{ zIndex: '2', height: '34px', width: '190px' }}
                    src={Logo}
                    alt=""
                  />
                </div>
                <Nav />
              </div>
            </Header>
            <Content style={{ position: 'relative', margin: 0, padding: 0 }}>
              <div style={{ minHeight: 500 }}>
                <Switch>
                  {routers.map((e) => {
                    return (
                      <Route render={(props) => <e.component key={props.match.params.id} />} exact path={e.path} key={e.path} />
                    );
                  })}
                </Switch>
              </div>
            </Content>
            <Footer style={{ position: 'relative', margin: 0, padding: 0 }}>
              <HomeFooter />
            </Footer>
          </Layout>
        </ScrollToTop>
      </HashRouter>
    );
  }
}

export default RouterConfig;
