import React from 'react';
import { Menu } from 'antd';
import { Link, withRouter } from 'react-router-dom';
import './index.scss'

const { SubMenu } = Menu;

class Index extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current: '/'
    };
  }

  componentDidMount() {
    if (this.props.location.pathname) {
      this.setState({
        current: this.props.location.pathname
      })
    }
  }

  handleClick = (e) => {
    this.setState({ current: e.key });
  };

  render() {
    const { current } = this.state;
    return (
      <div className="nav">
        <Menu
          onClick={this.handleClick}
          defaultSelectedKeys={[current]}
          selectedKeys={[current]}
          mode="horizontal"
          style={{
            height: '7vh'
          }}
        >
          <Menu.Item key="/home">
            <Link to="/" className="nav-home">首页</Link>
          </Menu.Item>
          <Menu.Item key="/solution">
            <Link to="/solution" className="nav-solution">解决方案</Link>
          </Menu.Item>
          <Menu.Item key="/relatedCases">
            <Link to="/relatedCases" className="nav-cases">相关案例</Link>
          </Menu.Item>
          <Menu.Item key="/news">
            <Link to="/news" className="nav-news">新闻资讯</Link>
          </Menu.Item>
          <Menu.Item key="/about">
            <Link to="/about" className="nav-about">关于我们</Link>
          </Menu.Item>
        </Menu>
      </div>
    );
  }
}

export default withRouter(Index);
