import React from 'react';
import { Row, Col } from 'antd';
import { Link } from 'react-router-dom';
import newsOfBanner from './news-banner.jpg';
import News0 from './news0.png';
import News1 from './news1.png';
import News2 from './news2.jpg';
import News3 from './news3.jpg';
import NewsLineVertical from './news-divider-vertical.png'
import NewsLineHorizontal from './news-divider-horizontal.png'
import {ids} from './constant';
import './index.scss';

const newsbox = [
  {
    id: ids[0],
    newsImg: News0,
    newsTitle: '2020智慧水务行业现状及发展前景分析',
    newsInfo: `我国是一个干旱缺水的国家，淡水资源总量约占全球水资源的6%，次于巴西、俄罗斯、加拿大、美国和印度尼西亚，居世界第6位，但人均仅为世界平均水平的1/4、美国的1/5，是全球人均水资源最贫乏的国家之一。2018年，我国水资源总量为27462.5亿立方米，同比减少4.5%，人均水资源量2008.00立方米，为轻度缺水。水务行业的传统领域包括供水和污水处理两个子行业，行业发展程度与经济增长水平、人口数量及城市化进程等因素高度相关，近年来我国城市及县城供水量和污水处理量保持稳定增长的趋势，行业运营基础稳定。`,
    newsYear: '2020',
    newsMonth: '06-09',
    newsLineBottom: NewsLineHorizontal
  },
  {
    id: ids[1],
    newsImg: News1,
    newsTitle: '水行业未来发展趋势',
    newsInfo: '水是生命之源、生态之本、生产之要，水务是事关经济社会、国计民生的最基本公共服务行业之一。我国自启动市政公用事业市场化改革以来，水务行业经历了跨越式发展，尤其是近几年来，在“水十条”、中央环保督察、城市水质排名、河湖库湾长制、新环保法、环保税法、碧水保卫战、绿色金融等一系列政策措施推动下，水务行业已成为我国环保产业市场规模最大、商业模式最清晰、运营管理最成熟的细分领域。',
    newsYear: '2020',
    newsMonth: '06-03',
    newsLineBottom: NewsLineHorizontal
  },
  {
    id: ids[2],
    newsImg: News2,
    newsTitle: '武汉水务集团与华为云共建智慧水务',
    newsInfo: '管家式服务很多人都听过，可你听说过城市供水领域的管家式服务吗？随着5月18日，武汉市水务集团（以下简称武水集团）"客户服务平台"在汉阳、汉口两地同时上线调试运行，生活在武汉市的居民将可以率先体验这一服务。',
    newsYear: '2020',
    newsMonth: '05-26',
    newsLineBottom: NewsLineHorizontal
  },
  {
    id: ids[3],
    newsImg: News3,
    newsTitle: '江苏智慧水务研究院浅析AI技术在涉水领域的应用',
    newsInfo: '随着科学技术的发展，在数字经济环境下，越来越多的新兴技术被应用到传统水务行业，推动着“智慧水务”向更高阶段发展。目前最受关注的十大技术主要包括云计算、物联网、大数据、移动互联网、实时动态监测、BIM建筑信息管理、GIS地理信息系统、3D打印、VR虚拟现实和AI人工智能技术',
    newsYear: '2020',
    newsMonth: '05-26',
    newsLineBottom: null
  }
];

class Index extends React.Component {
  componentWillMount() {
    document.getElementById('root').scrollIntoView(true);//为ture返回顶部，false为底部
  }

  render() {
    return (
      <div className="news">
        <div className="news-of-banner">
          <img style={{ width: '100%' }} src={newsOfBanner} alt="" />
        </div>
        {newsbox.map((item, index) => {
          return (
            <div className="newsbox">
              <Row>
                <Col span={2}></Col>
                <Col span={6} style={{ background: '#fff' }} data-aos="zoom-in">
                  <Link to={"/newsDetail/" + item.id}>
                    <img style={{ height: '20vh', width: '90%' }} src={item.newsImg} alt="" />
                  </Link>
                </Col>
                <Col span={12} style={{ background: '#fff', textAlign: 'left', width: '90%', padding: 10 }} data-aos="zoom-in">
                  <Link to={"/newsDetail/" + item.id}>
                    <div className="news-title">{item.newsTitle}</div>
                    <div className="news-info">{item.newsInfo}</div>
                  </Link>
                </Col>
                <Col span={1}><img style={{ height: '12vh' }} src={NewsLineVertical} alt="" /></Col>
                <Col span={1} style={{ background: '#fff', textAlign: 'center' }} data-aos="zoom-in">
                  <div style={{ fontSize: '20px' }}>{item.newsYear}</div>
                  <div>{item.newsMonth}</div>
                </Col>
                <Col span={2}></Col>
              </Row>
              <div><img className="news-line-horizontal" src={item.newsLineBottom} alt="" /></div>
            </div>);
        })}
      </div>
    );
  }
}


export default Index;
