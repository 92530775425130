import React from 'react';
import './index.scss';
import 'antd/dist/antd.css';
import Banner from '../components/Banner';
import RecommendationInformation from '../components/RecommendationInformation';
import ApplicationOfBigData from '../components/ApplicationOfBigData';
import Num from '../components/Num';
import News from '../components/News';

class Index extends React.Component {
  componentWillMount() {
    document.getElementById('root').scrollIntoView(true); //为ture返回顶部，false为底部
  }

  render() {
    return (
      <div className="App">
        <div style={{ minHeight: 500 }}>
          <div
            style={{
              zIndex: '-2'
            }}
          >
            <Banner />
          </div>
          <div style={{ margin: '0 auto', minHeight: '500px', width: '80%' }}>
            <div>
              <RecommendationInformation />
            </div>
            <div>
              <ApplicationOfBigData />
            </div>
          </div>
          <div style={{ margin: '0 auto', width: '100%' }}>
            <Num />
          </div>
          <div style={{ margin: '0 auto', width: '80%' }}>
            <div>
              <News />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Index;
