import React from 'react';
import { Carousel } from 'antd';
import Banner1 from './banner001.jpg';
import Banner2 from './banner002.jpg';
import Banner3 from './banner003.jpg';
import { LeftCircleOutlined, RightCircleOutlined } from '@ant-design/icons';
import './index.scss';

class Index extends React.Component {

  render() {
    return (
      <div className="banner">
        <Carousel ref={(carouselChange) => { this.carouselChange = carouselChange }} easing={null} autoplay style={{ margin: 0, padding: 0 }}>
          <div>
            <img className="banner-animation" style={{ width: '100%' }} src={Banner1} alt="" />
          </div>
          <div>
            <img className="banner-animation" style={{ width: '100%' }} src={Banner2} alt="" />
          </div>
          <div>
            <img className="banner-animation" style={{ width: '100%' }} src={Banner3} alt="" />
          </div>
        </Carousel>
        <LeftCircleOutlined className="carousel-left-circle" onClick={() => { this.carouselChange.prev() }} />
        <RightCircleOutlined className="carousel-right-circle" onClick={() => { this.carouselChange.next() }} />
      </div>
    );
  }
}

export default Index;
